import React, { ChangeEvent, useRef, useState } from 'react';
import { Button } from 'antd';

import styles from './ImageBlock.module.scss';

export default function ImageBlock() {
  const myInput = useRef<HTMLInputElement | null>(null);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedImage(event.target.files[0]);
    }
  };

  const handleClick = () => {
    myInput.current?.click();
  };

  return (
    <div className={styles.imageBlock}>
      <div className={styles.imageBlock_avatar}>
        {selectedImage ? (
          <img src={URL.createObjectURL(selectedImage)} alt='avatar' />
        ) : (
          <div className={styles.imageBlock_circle} />
        )}
      </div>
      <div>
        <Button onClick={handleClick}>Upload image</Button>
        <div className={styles.imageBlock_text}>*jpeg, jpg, png, svg formats</div>
      </div>
      <input type='file' ref={myInput} className={styles.imageBlock_input} onChange={handleChange} />
    </div>
  );
}
