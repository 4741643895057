import Role from './Role/Role';
import Status from './Status/Status';

import styles from './RoleStatusBlock.module.scss';

export default function RoleStatusBlock() {
  return (
    <div className={styles.roleStatusBlock}>
      <Role />
      <Status />
    </div>
  );
}
