import React, { useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, InputNumber, Slider } from 'antd';
import cn from 'classnames';

import styles from './RangeFilterDropdown.module.scss';

interface RangeFilterDropdownProps {
  min: number;
  max: number;
  minValue: number;
  maxValue: number;
  isChangeRange: boolean;
  label: string;
  placeholder: string;
  onSliderChange: (values: number[]) => void;
  onMinChange: (value: number | null) => void;
  onMaxChange: (value: number | null) => void;
}

export default function RangeFilterDropdown({
  min,
  max,
  minValue,
  maxValue,
  isChangeRange,
  label,
  placeholder,
  onSliderChange,
  onMinChange,
  onMaxChange,
}: RangeFilterDropdownProps) {
  const [visible, setVisible] = useState<boolean>(false);

  const dropdownContent = (
    <div className={styles.dropdownContent}>
      <div>
        <div>
          <Slider
            range
            min={min}
            max={max}
            value={[minValue, maxValue]}
            onChange={(values: number[]) => onSliderChange(values)}
            className={styles.slider}
          />
        </div>
      </div>
      <div className={styles.ranges}>
        <div>
          <div className={styles.ranges_label}>From</div>
          <InputNumber min={min} max={max} value={minValue} onChange={onMinChange} className={styles.inputNumber} />
        </div>
        <div>
          <div className={styles.ranges_label}>To</div>
          <InputNumber min={min} max={max} value={maxValue} onChange={onMaxChange} className={styles.inputNumber} />
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className={styles.label}>{label}</div>
      <Dropdown overlay={dropdownContent} open={visible} onOpenChange={setVisible} trigger={['click']}>
        <Button className={cn(styles.dropdownButton, visible && styles.dropdownButton_visible)}>
          {isChangeRange ? `${minValue} - ${maxValue}` : placeholder}{' '}
          <DownOutlined style={{ transform: visible ? 'rotate(180deg)' : 'rotate(0deg)' }} />
        </Button>
      </Dropdown>
    </>
  );
}
