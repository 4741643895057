import { createSlice } from '@reduxjs/toolkit';

import { UiState } from './types';

const initialState: UiState = {
  isShortSidebar: false,
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    toggleSidebarAction: (state) => {
      state.isShortSidebar = !state.isShortSidebar;
    },
  },
  extraReducers: () => {},
});

export const { toggleSidebarAction } = uiSlice.actions;

export default uiSlice.reducer;
