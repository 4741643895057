import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Radio } from 'antd';

import { useAppDispatch } from '../../../../store';
import { deleteAsset, getAssets } from '../../../../store/assets/asyncThunks/slice';

import styles from './DeleteAssetModal.module.scss';

const modalStyles = {
  content: {
    padding: 0,
  },
};

interface DeleteAssetModalProps {
  isModalOpen: boolean;
  assetId: number | null;
  handleCancel: () => void;
}

export default function DeleteAssetModal({ isModalOpen, assetId, handleCancel }: DeleteAssetModalProps) {
  const dispatch = useAppDispatch();

  const handleDelete = async () => {
    await dispatch(deleteAsset(assetId as number));
    handleCancel();
    dispatch(getAssets());
  };

  return (
    <Modal title='' open={isModalOpen} onCancel={handleCancel} footer={null} styles={modalStyles} closable={false}>
      <div className={styles.main}>
        <div className={styles.top}>
          <InfoCircleOutlined style={{ fontSize: 22, color: '#FAAD14' }} />
          <div>
            <div className={styles.title}>BTC Asset Deactivation</div>
            <div className={styles.text}>Before deactivation select one of the options below</div>
          </div>
        </div>
        <Radio.Group>
          <div className={styles.block}>
            <div className={styles.block_radio}>
              <Radio value={1}>Rollback</Radio>
            </div>
            <div className={styles.block_text}>
              This will roll back all bets associated with the deactivated asset and automatically refund the money to
              the users.
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.block_radio}>
              <Radio value={2}>Restrict placing new bets</Radio>
            </div>
            <div className={styles.block_text}>
              This will prevent users from placing any future bets on this asset without rolling back any existing bets
            </div>
          </div>
        </Radio.Group>
      </div>
      <div className={styles.footer}>
        <Button key='cancel' onClick={handleCancel} className={styles.cancelButton}>
          Cancel
        </Button>
        <Button key='ok' htmlType='submit' className={styles.okButton} onClick={handleDelete}>
          Deactivate
        </Button>
      </div>
    </Modal>
  );
}
