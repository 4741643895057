import InputField from '../../../../../components/InputField/InputField';

import styles from './PasswordBlock.module.scss';

interface PasswordBlockProps {
  onGeneratePassword: () => void;
}

export default function PasswordBlock({ onGeneratePassword }: PasswordBlockProps) {
  return (
    <div className={styles.passwordBlock}>
      <div className={styles.field}>
        <InputField
          label='Password'
          name='password'
          rules={[
            { required: true, message: 'Please enter your password' },
            { min: 8, message: 'Password must be at least 8 characters long' },
          ]}
          placeholder='Input password'
          type='password'
        />
      </div>
      <div className={styles.text} onClick={onGeneratePassword}>
        Generate password
      </div>
    </div>
  );
}
