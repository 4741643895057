import React, { useState } from 'react';
import { TablePaginationConfig, TableProps, Tag } from 'antd';
import { Checkbox, Table } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';

import ConfirmModal from '../../../components/ConfirmModal/ConfirmModal';

import Actions from './Actions/Actions';
import EditAdminModal from './EditAdminModal/EditAdminModal';
import { initialData } from './data';
import { DataType } from './types';

import styles from './AdminManagementTable.module.scss';

const AdminManagementTable: React.FC = () => {
  const [data, setData] = useState<DataType[]>(initialData.map((item) => ({ ...item, selected: false })));
  const [allSelected, setAllSelected] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState<boolean>(false);
  const [deleteAdminEmail, setDeleteAdminEmail] = useState<string>('');
  const [editData, setEditData] = useState<DataType | null>(null);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });

  const handleCheckboxChange = (checked: boolean, key: string) => {
    const updatedData = data.map((item) => (item.key === key ? { ...item, selected: checked } : item));
    setData(updatedData);
  };

  const handleSelectAllChange = (e: CheckboxChangeEvent) => {
    const checked = e.target.checked;
    const updatedData = data.map((item) => ({ ...item, selected: checked }));
    setData(updatedData);
    setAllSelected(checked);
  };

  const handleOpenEditModal = (dataEditAdmin: DataType) => {
    setEditData(dataEditAdmin);
    setIsOpenEditModal(true);
  };

  const handleTableChange = (paginationData: TablePaginationConfig) => {
    setPagination({ current: paginationData.current ?? 1, pageSize: paginationData.pageSize ?? 10 });
  };

  const handlePaginationChange = (page: number, pageSize?: number) => {
    handleTableChange({ current: page, pageSize });
  };

  const handleOpenDeleteModal = (value: string) => {
    setDeleteAdminEmail(value);
    setIsOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setIsOpenDeleteModal(false);
  };

  const handleDelete = () => {};

  const handleCloseEditModal = () => {
    setIsOpenEditModal(false);
  };

  const handleEdit = () => {
    setIsOpenEditModal(false);
  };

  const columns: TableProps<DataType>['columns'] = [
    {
      title: <Checkbox checked={allSelected} onChange={handleSelectAllChange} />,
      dataIndex: 'checkbox',
      key: 'checkbox',
      render: (_, record: DataType) => (
        <Checkbox checked={record.selected} onChange={(e) => handleCheckboxChange(e.target.checked, record.key)} />
      ),
      width: '4%',
    },
    {
      title: 'Full Name',
      dataIndex: 'fullName',
      key: 'fullName',
      sorter: (a, b) => a.fullName.localeCompare(b.fullName),
      width: '20%',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '20%',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      sorter: (a, b) => a.role.localeCompare(b.role),
      render: (text) => (
        <Tag color={text === 'Admin' ? 'purple' : 'blue'} style={{ textAlign: 'center' }}>
          {text}
        </Tag>
      ),
      width: '12%',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (text) => <Tag color={text === 'Active' ? 'green' : 'red'}>{text}</Tag>,
      width: '12%',
    },
    {
      title: 'Last Active',
      dataIndex: 'lastActive',
      key: 'lastActive',
      sorter: (a, b) => a.lastActive.localeCompare(b.lastActive),
      width: '15%',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Actions record={record} onOpenEditModal={handleOpenEditModal} onOpenDeleteModal={handleOpenDeleteModal} />
      ),
      width: '10%',
    },
  ];

  return (
    <div className={styles.wrapper}>
      <Table<DataType>
        columns={columns}
        dataSource={data}
        rowKey='key'
        pagination={{
          current: pagination.current,
          pageSize: pagination.pageSize,
          total: data.length,
          showSizeChanger: true,
          showQuickJumper: true,
          onChange: handlePaginationChange,
        }}
        onChange={handleTableChange}
        scroll={{ y: window.innerHeight - 205 }}
      />
      <ConfirmModal
        isModalOpen={isOpenDeleteModal}
        title='Are you sure delete this admin?'
        text={
          <>
            User with email <span>{deleteAdminEmail}</span> will no longer have access to CoinPoker Admin panel.
          </>
        }
        okButtonText='Delete'
        handleCancel={handleCloseDeleteModal}
        handleOk={handleDelete}
      />
      <EditAdminModal
        isOpenModal={isOpenEditModal}
        editAdminData={editData}
        handleOk={handleEdit}
        handleCancel={handleCloseEditModal}
      />
    </div>
  );
};

export default AdminManagementTable;
