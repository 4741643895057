import React, { useState } from 'react';
import { Button, Form, Modal } from 'antd';

import { useAppDispatch } from '../../../../store';
import { createAsset, getAssets } from '../../../../store/assets/asyncThunks/slice';
import CryptoAssetForm from '../../CryptoAssetForm/CryptoAssetForm';

import styles from './AddAssetModal.module.scss';

const modalStyles = {
  content: {
    padding: 0,
    width: 600,
  },
  header: {
    padding: '16px 24px',
    borderBottom: '1px solid #F0F0F0',
    marginBottom: 0,
  },
};

interface AddAssetModalProps {
  isOpenModal: boolean;
  handleOk: () => void;
  handleCancel: () => void;
}

interface FormValues {
  id: number;
  name: string;
  symbol: string;
  type: string;
  isActive: boolean;
  icon: string;
  multiplierMin: number;
  multiplierMax: number;
  wagerMin: number;
  wagerMax: number;
  takeProfitLimit: number;
  stopLossLimit: number;
  flatFeePercent: number;
  pnlFeePercent: number;
  fundingFeePercent: number;
  fundingFeeTimeframe: number;
}

export default function AddAssetModal({ isOpenModal, handleOk, handleCancel }: AddAssetModalProps) {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const [status, setStatus] = useState<boolean>(false);

  const handleChangeStatus = () => {
    setStatus((prev) => !prev);
  };

  const onSubmit = async (values: FormValues) => {
    const requestBody = {
      name: values.name,
      symbol: values.symbol,
      type: 'CRYPTO',
      isActive: status,
      icon: 'icon.png',
      multiplierMin: values.multiplierMin,
      multiplierMax: values.multiplierMax,
      wagerMin: values.wagerMin,
      wagerMax: values.wagerMax,
      takeProfitLimit: values.takeProfitLimit,
      stopLossLimit: values.stopLossLimit,
      flatFeePercent: values.flatFeePercent,
      pnlFeePercent: values.pnlFeePercent,
      fundingFeePercent: values.fundingFeePercent,
      fundingFeeTimeframe: values.fundingFeeTimeframe,
    };
    await dispatch(createAsset(requestBody));
    await dispatch(getAssets());
    handleOk();
  };

  return (
    <Modal title='Add asset' open={isOpenModal} onCancel={handleCancel} footer={null} styles={modalStyles}>
      <Form onFinish={onSubmit} requiredMark={false} form={form}>
        <CryptoAssetForm form={form} status={status} onToggle={handleChangeStatus} />
        <div className={styles.footer}>
          <Button key='cancel' onClick={handleCancel} className={styles.cancelButton}>
            Cancel
          </Button>
          <Button key='ok' type='primary' htmlType='submit' className={styles.okButton}>
            Add asset
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
