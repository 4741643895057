import { useEffect, useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import { FilterOutlined, UpOutlined } from '@ant-design/icons';

import { FilterRange } from '../../types';

import FilterDropdownContent from './FilterDropdownContent/FilterDropdownContent';

import styles from './FilterDropdown.tsx.module.scss';

interface FilterDropdownProps {
  selectedAsset: string;
  selectedUser: string;
  selectedStatus: string;
  wagerRange: FilterRange;
  multiplierRange: FilterRange;
  setSelectedAsset: (asset: string) => void;
  setSelectedUser: (user: string) => void;
  setSelectedStatus: (status: string) => void;
  setWagerRange: (range: FilterRange) => void;
  setMultiplierRange: (range: FilterRange) => void;
  onApply: () => void;
}

export default function FilterDropdown({
  setSelectedAsset,
  setSelectedUser,
  setSelectedStatus,
  setWagerRange,
  setMultiplierRange,
  onApply,
  ...filters
}: FilterDropdownProps) {
  const dropdownInnerRef = useRef<HTMLDivElement | null>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [visible, setVisible] = useState<boolean>(false);

  const handleClick = () => {
    setVisible((prev) => !prev);
  };

  const handleApply = () => {
    setVisible(false);
    onApply();
  };

  useClickAway(dropdownInnerRef, ({ target }) => {
    if (!dropdownRef.current?.contains(target as Node) && visible) {
      handleClick();
    }
  });

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && visible) {
        setVisible(false);
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [visible]);

  return (
    <div className={styles.filter}>
      <div className={styles.filter_dropdown} onClick={handleClick} ref={dropdownRef}>
        <FilterOutlined style={{ marginRight: 8 }} />
        Select filters
        <UpOutlined style={{ marginLeft: 4, transform: visible ? 'rotate(0deg)' : 'rotate(180deg)' }} />
      </div>
      <FilterDropdownContent
        visible={visible}
        dropdownInnerRef={dropdownInnerRef}
        setSelectedAsset={setSelectedAsset}
        setSelectedUser={setSelectedUser}
        setSelectedStatus={setSelectedStatus}
        setWagerRange={setWagerRange}
        setMultiplierRange={setMultiplierRange}
        onClose={handleClick}
        onApply={handleApply}
        {...filters}
      />
    </div>
  );
}
