import React, { useCallback, useEffect, useState } from 'react';

import { useTypedSelector } from '../../hooks/useTypedSelector';
import { useAppDispatch } from '../../store';
import { getBets } from '../../store/bets/asyncThunks/slice';

import BetsManagementTable from './BetsManagementTable/BetsManagementTable';
import Toolbar from './Toolbar/Toolbar';
import { FilterItem, FilterRange, Filters } from './types';

const MIN_VALUE = 0;
const MAX_VALUE = 1000;

export default function BetsManagement() {
  const dispatch = useAppDispatch();

  const [filters, setFilters] = useState<Filters>({
    selectedAsset: '',
    selectedUser: '',
    selectedStatus: '',
    multiplierRange: { minValue: MIN_VALUE, maxValue: MAX_VALUE, isChangeRange: false },
    wagerRange: { minValue: MIN_VALUE, maxValue: MAX_VALUE, isChangeRange: false },
  });

  const [filterList, setFilterList] = useState<FilterItem[]>([]);

  const { paginationConfig, sort } = useTypedSelector((state) => state.bets);

  const handleApply = useCallback(() => {
    const newFilterList: FilterItem[] = [
      { type: 'By asset', value: filters.selectedAsset },
      { type: 'By user', value: filters.selectedUser },
      { type: 'By multiplier', ...filters.multiplierRange },
      { type: 'By wager', ...filters.wagerRange },
      { type: 'By status', value: filters.selectedStatus },
    ].filter((item) => {
      if ('value' in item) {
        return !!item.value;
      }
      return !!item.isChangeRange;
    });

    setFilterList(newFilterList);
  }, [filters]);

  const handleRemoveFilterItem = useCallback((value: string) => {
    setFilterList((prev) => prev.filter((item) => item.type !== value));
    setFilters((prev) => ({
      ...prev,
      ...(value === 'By asset' && { selectedAsset: '' }),
      ...(value === 'By user' && { selectedUser: '' }),
      ...(value === 'By multiplier' && {
        multiplierRange: { minValue: MIN_VALUE, maxValue: MAX_VALUE, isChangeRange: false },
      }),
      ...(value === 'By wager' && {
        wagerRange: { minValue: MIN_VALUE, maxValue: MAX_VALUE, isChangeRange: false },
      }),
      ...(value === 'By status' && { selectedStatus: '' }),
    }));
  }, []);

  useEffect(() => {
    const params = {
      page: paginationConfig.page - 1,
      size: paginationConfig.size,
      sort: sort,
    };
    dispatch(getBets(params));
  }, [paginationConfig.page, paginationConfig.size, sort]);

  return (
    <>
      <Toolbar
        filterList={filterList}
        setSelectedAsset={(asset: string) => setFilters((prev) => ({ ...prev, selectedAsset: asset }))}
        setSelectedUser={(user: string) => setFilters((prev) => ({ ...prev, selectedUser: user }))}
        setSelectedStatus={(status: string) => setFilters((prev) => ({ ...prev, selectedStatus: status }))}
        setMultiplierRange={(range: FilterRange) => setFilters((prev) => ({ ...prev, multiplierRange: range }))}
        setWagerRange={(range: FilterRange) => setFilters((prev) => ({ ...prev, wagerRange: range }))}
        onApply={handleApply}
        onRemoveFilterItem={handleRemoveFilterItem}
        {...filters}
      />
      <BetsManagementTable isAppliedFilters={filterList.length > 0} />
    </>
  );
}
