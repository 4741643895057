import React from 'react';

import ApiService from './services/ApiService';
import AppRoutes from './routes';

function App() {
  return (
    <div>
      <ApiService />
      <AppRoutes />
    </div>
  );
}

export default App;
