import { Outlet, useRoutes } from 'react-router-dom';

import AuthorizedLayout from '../layouts/AuthorizedLayout/AuthorizedLayout';
import AdminManagementPage from '../pages/AdminManagementPage/AdminManagementPage';
import BetsManagement from '../pages/BetsManagement/BetsManagement';
import CryptoAssetsPage from '../pages/CryptoAssetsPage/CryptoAssetsPage';
import ForgotPasswordPage from '../pages/ForgotPasswordPage/ForgotPasswordPage';
import LoginPage from '../pages/LoginPage/LoginPage';
import ProfileDetailsPage from '../pages/ProfileDetailsPage/ProfileDetailsPage';
import SetNewPasswordPage from '../pages/SetNewPasswordPage/SetNewPasswordPage';

import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';

const publicRoutes = [
  { path: '', element: <LoginPage /> },
  { path: 'forgot-password', element: <ForgotPasswordPage /> },
  { path: 'set-new-password', element: <SetNewPasswordPage /> },
];

const privateRoutes = [
  { path: 'admin-management', element: <AdminManagementPage /> },
  { path: 'profile-details', element: <ProfileDetailsPage /> },
  { path: 'bets-management', element: <BetsManagement /> },
  { path: 'crypto-assets', element: <CryptoAssetsPage /> },
];

export default function AppRoutes() {
  const routes = useRoutes([
    {
      path: '/',
      children: [
        {
          path: '',
          element: (
            <PublicRoutes>
              <Outlet />
            </PublicRoutes>
          ),
          children: publicRoutes,
        },
        {
          path: '',
          element: (
            <PrivateRoutes>
              <AuthorizedLayout>
                <Outlet />
              </AuthorizedLayout>
            </PrivateRoutes>
          ),
          children: privateRoutes,
        },
        {
          path: '*',
          element: <div>Not found</div>,
        },
      ],
    },
  ]);

  return routes;
}
