import type { GetProps } from 'antd';
import { Input } from 'antd';

import styles from './CustomSearch.module.scss';

type SearchProps = GetProps<typeof Input.Search>;

const { Search } = Input;

export default function CustomSearch() {
  const onSearch: SearchProps['onSearch'] = (value, _e, info) => console.log(info?.source, value);

  return (
    <div className={styles.search}>
      <Search placeholder={'Search...'} onSearch={onSearch} style={{ width: 264 }} />
    </div>
  );
}
