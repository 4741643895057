import { Button, Form } from 'antd';

import InputField from '../../../components/InputField/InputField';

import styles from './SetNewPasswordForm.module.scss';

type ValuesType = {
  password: string;
  confirmPassword: string;
};

export default function SetNewPasswordForm() {
  const onSubmit = async (values: ValuesType) => {
    console.log('values', values);
  };

  return (
    <Form onFinish={onSubmit} className={styles.form} requiredMark={false}>
      <InputField
        label='New password'
        name='password'
        rules={[
          { required: true, message: 'Please enter new password' },
          { min: 8, message: 'Password must be at least 8 characters long' },
        ]}
        placeholder='input password'
        type='password'
      />
      <InputField
        label='Confirm password'
        name='confirmPassword'
        rules={[
          { required: true, message: 'Confirm new password' },
          { min: 8, message: 'Password must be at least 8 characters long' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Passwords do not match!!'));
            },
          }),
        ]}
        placeholder='input password'
        type='password'
      />
      <div className={styles.formItem}>
        <Button type='primary' htmlType='submit' className={styles.formItem_button}>
          Reset password
        </Button>
      </div>
    </Form>
  );
}
