import React from 'react';

import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
import { FilterItem } from '../../types';

import styles from './FilterList.module.scss';

interface FilterListProps {
  filterList: FilterItem[];
  onRemoveFilterItem: (value: string) => void;
}

export default function FilterList({ filterList, onRemoveFilterItem }: FilterListProps) {
  if (!filterList.length) {
    return null;
  }
  return (
    <div className={styles.filters}>
      <div className={styles.filters_inner}>
        <div>{filterList.length > 1 ? 'Applied filters:' : 'Applied filter:'}</div>
        <div className={styles.filters_inner_list}>
          {filterList.map((item) => (
            <div key={item.type} className={styles.filters_inner_list_item}>
              {item.type} <CloseIcon onClick={() => onRemoveFilterItem(item.type)} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
