import { Button, Form, Modal } from 'antd';

import InputField from '../../../../components/InputField/InputField';
import { generatePassword } from '../../../../helpers/helpers';

import PasswordBlock from './PasswordBlock/PasswordBlock';
import RoleStatusBlock from './RoleStatusBlock/RoleStatusBlock';

import styles from './AddAdminModal.module.scss';

const modalStyles = {
  content: {
    padding: 0,
  },
  header: {
    padding: '16px 24px',
    borderBottom: '1px solid #F0F0F0',
    marginBottom: 0,
  },
};

interface AddAdminModalProps {
  isOpenModal: boolean;
  handleOk: () => void;
  handleCancel: () => void;
}

interface ValuesData {
  fullName: string;
  email: string;
  password: string;
}

export default function AddAdminModal({ isOpenModal, handleOk, handleCancel }: AddAdminModalProps) {
  const [form] = Form.useForm();

  const handleGeneratePassword = () => {
    const newPassword = generatePassword(12);
    form.setFieldsValue({ password: newPassword });
  };

  const onSubmit = async (values: ValuesData) => {
    console.log('values', values);
    handleOk();
  };

  return (
    <Modal title='Add admin' open={isOpenModal} onCancel={handleCancel} footer={null} styles={modalStyles}>
      <Form onFinish={onSubmit} className={styles.form} requiredMark={false} form={form}>
        <div className={styles.main}>
          <InputField
            label='Full name'
            name='fullName'
            rules={[
              { required: true, message: 'Please enter your full name' },
              { min: 3, message: 'Full name must be at least 3 characters long' },
              {
                pattern: /^[A-Za-z\s]+$/,
                message: 'Full name must contain only letters and spaces',
              },
            ]}
            placeholder='Enter full name'
          />
          <InputField
            label='Email'
            name='email'
            rules={[
              { required: true, message: 'Please enter your email' },
              { type: 'email', message: 'Please enter a valid email' },
            ]}
            placeholder='Enter user’s email'
          />
          <PasswordBlock onGeneratePassword={handleGeneratePassword} />
          <RoleStatusBlock />
        </div>
        <div className={styles.footer}>
          <Button key='cancel' onClick={handleCancel} className={styles.cancelButton}>
            Cancel
          </Button>
          <Button key='ok' type='primary' htmlType='submit' className={styles.okButton}>
            Send invite
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
