import { ChangeEvent, useRef, useState } from 'react';
import { Button } from 'antd';

import { ReactComponent as AvatarIcon } from '../../../assets/icons/avatar.svg';

import styles from './Avatar.module.scss';

export default function Avatar() {
  const myInput = useRef<HTMLInputElement | null>(null);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);

  const handleClick = () => {
    myInput.current?.click();
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedImage(event.target.files[0]);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.image}>
        {selectedImage ? <img src={URL.createObjectURL(selectedImage)} alt='avatar' /> : <AvatarIcon />}
      </div>
      <Button onClick={handleClick}>Upload</Button>
      <input type='file' ref={myInput} className={styles.input} onChange={handleChange} />
    </div>
  );
}
