import { ReactNode } from 'react';

import { ReactComponent as LogoIcon } from '../../assets/icons/logo.svg';

import styles from './UnauthorizedLayout.module.scss';

interface UnauthorizedLayoutProps {
  subtext?: string;
  title?: string;
  text?: string;
  children: ReactNode;
}

export default function UnauthorizedLayout({ subtext, title, text, children }: UnauthorizedLayoutProps) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.card}>
        <div className={styles.main}>
          {subtext && <div className={styles.main_subtext}>{subtext}</div>}
          {title && <div className={styles.main_title}>{title}</div>}
          {text && <div className={styles.main_text}>{text}</div>}
          {children}
        </div>
        <div className={styles.image}>
          <LogoIcon />
        </div>
      </div>
    </div>
  );
}
