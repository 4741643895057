import { DataType } from './types';

export const initialData: DataType[] = [
  {
    key: '1',
    fullName: 'Richard Tall',
    email: 'richard.t@coinpoker.io',
    password: '123456789',
    role: 'Admin',
    status: 'Active',
    lastActive: 'Online now',
  },
  {
    key: '2',
    fullName: 'Jane Smith',
    email: 'jane.smith@coinpoker.io',
    password: '987654321',
    role: 'Super Admin',
    status: 'Blocked',
    lastActive: 'Last seen 1 hour ago',
  },
  {
    key: '3',
    fullName: 'Michael Brown',
    email: 'michael.brown@coinpoker.io',
    password: 'abcdefg123',
    role: 'Admin',
    status: 'Active',
    lastActive: 'Online now',
  },
  {
    key: '4',
    fullName: 'Lucy Johnson',
    email: 'lucy.johnson@coinpoker.io',
    password: 'pass1234',
    role: 'Super Admin',
    status: 'Active',
    lastActive: 'Last seen 30 minutes ago',
  },
  {
    key: '5',
    fullName: 'David Lee',
    email: 'david.lee@coinpoker.io',
    password: 'qwerty789',
    role: 'Super Admin',
    status: 'Blocked',
    lastActive: 'Last seen 3 days ago',
  },
  {
    key: '6',
    fullName: 'Emily Davis',
    email: 'emily.davis@coinpoker.io',
    password: 'emily123',
    role: 'Admin',
    status: 'Blocked',
    lastActive: 'Online now',
  },
  {
    key: '7',
    fullName: 'John Williams',
    email: 'john.williams@coinpoker.io',
    password: 'johnw123',
    role: 'Super Admin',
    status: 'Blocked',
    lastActive: 'Last seen 2 hours ago',
  },
  {
    key: '8',
    fullName: 'Laura Martinez',
    email: 'laura.martinez@coinpoker.io',
    password: 'laura789',
    role: 'Super Admin',
    status: 'Active',
    lastActive: 'Online now',
  },
  {
    key: '9',
    fullName: 'Chris Taylor',
    email: 'chris.taylor@coinpoker.io',
    password: 'chris456',
    role: 'Super Admin',
    status: 'Blocked',
    lastActive: 'Last seen 5 days ago',
  },
  {
    key: '10',
    fullName: 'Sarah White',
    email: 'sarah.white@coinpoker.io',
    password: 'sarahpass',
    role: 'Admin',
    status: 'Active',
    lastActive: 'Online now',
  },
  {
    key: '11',
    fullName: 'Richard White',
    email: 'richard.t@coinpoker.io',
    password: '123456789',
    role: 'Admin',
    status: 'Active',
    lastActive: 'Online now',
  },
];
