import { createSlice } from '@reduxjs/toolkit';

import { getBets } from './asyncThunks/slice';
import { BetsState } from './types';

const initialState: BetsState = {
  bets: [],
  loading: false,
  error: null,
  paginationConfig: {
    page: 1,
    size: 10,
    total: null,
  },
  sort: '',
};

export const betsSlice = createSlice({
  name: 'bets',
  initialState,
  reducers: {
    changePaginationAction: (state, action) => {
      state.paginationConfig.page = action.payload.current;
      state.paginationConfig.size = action.payload.pageSize;
    },
    changeSortAction: (state, action) => {
      state.sort = action.payload.sort;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBets.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBets.fulfilled, (state, action) => {
      const {
        payload: { data },
      } = action || {};
      state.bets = data.content;
      state.paginationConfig.page = data.number + 1;
      state.paginationConfig.size = data.size;
      state.paginationConfig.total = data.totalElements;
      state.loading = false;
    });
    builder.addCase(getBets.rejected, (state, action) => {
      state.bets = [];
      state.loading = false;
      state.error = 'Unknown error';
    });
  },
});

export const { changePaginationAction, changeSortAction } = betsSlice.actions;

export default betsSlice.reducer;
