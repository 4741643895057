import { ReactNode } from 'react';
import cn from 'classnames';

import Sidebar from '../../components/Sidebar/Sidebar';
import { useTypedSelector } from '../../hooks/useTypedSelector';

import styles from './AuthorizedLayout.module.scss';

interface AuthorizedLayoutProps {
  children: ReactNode;
}

export default function AuthorizedLayout({ children }: AuthorizedLayoutProps) {
  const { isShortSidebar } = useTypedSelector((state) => state.ui);
  return (
    <div className={styles.wrapper}>
      <Sidebar />
      <div className={cn(styles.main, isShortSidebar && styles.main_short)}>{children}</div>
    </div>
  );
}
