import React from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Space, Tooltip } from 'antd';

import { DataType } from '../types';

interface ActionsProps {
  record: DataType;
  onOpenEditModal: (record: DataType) => void;
  onOpenDeleteModal: (recordToggle: boolean) => void;
}

export default function Actions({ record, onOpenEditModal, onOpenDeleteModal }: ActionsProps) {
  return (
    <Space size='middle'>
      <Tooltip title={'Edit'}>
        <EditOutlined
          onClick={() => onOpenEditModal(record)}
          style={{ color: '#00000073', fontSize: '16px', cursor: 'pointer' }}
        />
      </Tooltip>
      <Tooltip title={record.isActive ? 'To delete asset it should be deactivated via toggle first' : 'Delete'}>
        <DeleteOutlined
          onClick={() => onOpenDeleteModal(record.isActive)}
          style={{ color: '#FF4D4F', fontSize: '16px', cursor: 'pointer', opacity: record.isActive ? '0.2' : '1' }}
        />
      </Tooltip>
    </Space>
  );
}
