import { Link } from 'react-router-dom';
import { Button, Checkbox, Form } from 'antd';

import InputField from '../../../components/InputField/InputField';
import { useAppDispatch } from '../../../store';
import { loginAction } from '../../../store/user/slice';

import styles from './LoginForm.module.scss';

type ValuesType = {
  email: string;
  password: string;
};

export default function LoginForm() {
  const dispatch = useAppDispatch();
  const onSubmit = async (values: ValuesType) => {
    console.log('values', values);
    dispatch(loginAction());
  };

  const handleChange = () => {
    console.log('On change');
  };

  return (
    <Form onFinish={onSubmit} className={styles.form} requiredMark={false}>
      <InputField
        label='Email'
        name='email'
        rules={[
          { required: true, message: 'Please enter your email' },
          { type: 'email', message: 'Please enter a valid email' },
        ]}
        placeholder='Enter your email'
      />
      <InputField
        label='Password'
        name='password'
        rules={[{ required: true, message: 'Please enter your password' }]}
        placeholder='input password'
        type='password'
      />
      <div className={styles.formCheckboxWrapper}>
        <Form.Item className={styles.formCheckbox}>
          <Checkbox onChange={handleChange}>Remember me</Checkbox>
        </Form.Item>
        <Link to={'forgot-password'} className={styles.formCheckboxWrapper_link}>
          Forgot password?
        </Link>
      </div>
      <div className={styles.formItem}>
        <Button type='primary' htmlType='submit' className={styles.formItem_button}>
          Login
        </Button>
      </div>
    </Form>
  );
}
