import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'antd';

import { useAppDispatch } from '../../../../store';
import { editAsset, getAssets } from '../../../../store/assets/asyncThunks/slice';
import CryptoAssetForm from '../../CryptoAssetForm/CryptoAssetForm';
import { DataType } from '../types';

import styles from './EditAssetModal.module.scss';

const modalStyles = {
  content: {
    padding: 0,
    width: 600,
  },
  header: {
    padding: '16px 24px',
    borderBottom: '1px solid #F0F0F0',
    marginBottom: 0,
  },
};

interface EditAssetModalProps {
  isModalOpen: boolean;
  editAssetData: DataType | null;
  handleCancel: () => void;
}

interface FormValues {
  name: string;
  symbol: string;
  wagerMin: number;
  wagerMax: number;
  multiplierMin: number;
  multiplierMax: number;
  takeProfitLimit: number;
  stopLossLimit: number;
  flatFeePercent: number;
  pnlFeePercent: number;
  fundingFeePercent: number;
  fundingFeeTimeframe: number;
}

export default function EditAssetModal({ isModalOpen, editAssetData, handleCancel }: EditAssetModalProps) {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [statusValue, setStatusValue] = useState<boolean>(false);

  useEffect(() => {
    if (editAssetData) {
      form.setFieldsValue({
        ...editAssetData,
        isActive: editAssetData.isActive,
      });
      setStatusValue(editAssetData.isActive || false);
    }
  }, [form, editAssetData]);

  const onSubmit = async (values: FormValues) => {
    const { id, type } = editAssetData || {};
    await dispatch(
      editAsset({
        assetId: id as number,
        body: { ...values, icon: editAssetData?.icon, type, isActive: statusValue },
      }),
    );
    await dispatch(getAssets());
    handleCancel();
  };

  const handleChangeStatus = () => {
    setStatusValue((prev) => !prev);
  };

  return (
    <Modal title='Edit asset' open={isModalOpen} onCancel={handleCancel} footer={null} styles={modalStyles}>
      <Form onFinish={onSubmit} requiredMark={false} form={form}>
        <CryptoAssetForm form={form} status={statusValue} onToggle={handleChangeStatus} />
        <div className={styles.footer}>
          <Button key='cancel' onClick={handleCancel} className={styles.cancelButton}>
            Cancel
          </Button>
          <Button key='ok' type='primary' htmlType='submit' className={styles.okButton}>
            Edit asset
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
