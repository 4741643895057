import { useState } from 'react';
import { Radio, RadioChangeEvent } from 'antd';

import styles from './Status.module.scss';

export default function Status() {
  const [value, setValue] = useState<number>(1);

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  return (
    <div>
      <div className={styles.title}>Status</div>
      <Radio.Group onChange={onChange} value={value}>
        <Radio value={1} className={styles.customRadio}>
          Active
        </Radio>
        <Radio value={2} className={styles.customRadio}>
          Blocked
        </Radio>
      </Radio.Group>
    </div>
  );
}
