import UnauthorizedLayout from '../../layouts/UnauthorizedLayout/UnauthorizedLayout';

import SetNewPasswordForm from './SetNewPasswordForm/SetNewPasswordForm';

export default function SetNewPasswordPage() {
  return (
    <UnauthorizedLayout
      title='Set new password'
      text='Your new password must be different from previous used passwords.'
    >
      <SetNewPasswordForm />
    </UnauthorizedLayout>
  );
}
