import { ReactNode } from 'react';
import { Form, FormItemProps, InputNumber } from 'antd';

import styles from './DoubleInputField.module.scss';

interface InputFieldProps {
  label: string | ReactNode;
  name1: string;
  name2: string;
  rules1?: FormItemProps['rules'];
  rules2?: FormItemProps['rules'];
  placeholder1: string;
  placeholder2: string;
  disabled?: boolean;
}

export default function DoubleInputField({
  label,
  name1,
  name2,
  rules1,
  rules2,
  placeholder1,
  placeholder2,
  disabled = false,
}: InputFieldProps) {
  return (
    <Form.Item className={styles.item} label={label}>
      <div className={styles.item_inner}>
        <Form.Item name={name1} rules={rules1} noStyle>
          <InputNumber type='number' placeholder={placeholder1} disabled={disabled} />
        </Form.Item>
        <Form.Item name={name2} rules={rules2} noStyle>
          <InputNumber type='number' placeholder={placeholder2} disabled={disabled} />
        </Form.Item>
      </div>
    </Form.Item>
  );
}
