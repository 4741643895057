import { createSlice } from '@reduxjs/toolkit';

import { UserState } from './types';

const token = Boolean(localStorage.getItem('_token'));

const initialState: UserState = {
  token: null,
  isAuth: token || false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logoutAction: (state) => {
      localStorage.removeItem('_token');
      state.token = null;
      state.isAuth = false;
    },
    loginAction: (state) => {
      localStorage.setItem('_token', '12345');
      state.isAuth = true;
    },
  },
  extraReducers: () => {},
});

export const { logoutAction, loginAction } = userSlice.actions;

export default userSlice.reducer;
