import React from 'react';
import { TablePaginationConfig, TableProps } from 'antd';
import { Table } from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import cn from 'classnames';

import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { useAppDispatch } from '../../../store';
import { changePaginationAction, changeSortAction } from '../../../store/bets/slice';

import { DataType } from './types';

import styles from './BetsManagementTable.module.scss';

interface BetsManagementTableProps {
  isAppliedFilters: boolean;
}

const STATUSES = {
  ACTIVE: 'Active',
  CLOSED: 'Closed',
};

const BetsManagementTable: React.FC<BetsManagementTableProps> = ({ isAppliedFilters }) => {
  const dispatch = useAppDispatch();

  const { bets, paginationConfig, loading } = useTypedSelector((state) => state.bets);

  const newData = bets?.map((item: any) => {
    return {
      key: item.id,
      id: item.id,
      playerNickname: item.playerNickname,
      assetName: item.assetName,
      status: item.status,
      wager: '$2.00',
      multiplier: item.multiplier,
      entryPrice: item.openPrice,
      exitPrice: '$62,867.41',
      commissionFee: item.commissionFee,
      roi: '+5%',
      pnl: '+$1.99',
      assetIcon: item.assetIcon,
    };
  });

  const handleTableChange = (
    paginationData: TablePaginationConfig,
    _?: Record<string, FilterValue | null>,
    sorter?: SorterResult<DataType> | SorterResult<DataType>[],
  ) => {
    const getSortOrder = (val: string) => {
      if (val === 'ascend') {
        return 'asc';
      }
      if (val === 'descend') {
        return 'desc';
      }
      return '';
    };

    let sortOrder = '';
    if (Array.isArray(sorter)) {
      sortOrder = sorter.length > 0 && sorter[0].order ? `${sorter[0].columnKey},${getSortOrder(sorter[0].order)}` : '';
    } else if (sorter?.order) {
      sortOrder = `${sorter.columnKey},${getSortOrder(sorter.order)}`;
    }
    dispatch(
      changePaginationAction({
        current: paginationData.current ?? 1,
        pageSize: paginationData.pageSize ?? 10,
      }),
    );
    dispatch(
      changeSortAction({
        sort: sortOrder,
      }),
    );
  };

  const handlePaginationChange = (page: number, pageSize?: number) => {
    handleTableChange({ current: page, pageSize });
  };

  const columns: TableProps<DataType>['columns'] = [
    {
      title: 'Bet ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
      width: '7%',
    },
    {
      title: 'Username',
      dataIndex: 'playerNickname',
      key: 'playerNickname',
      sorter: true,
      render: (text) => {
        return <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>{text}</div>;
      },
    },
    {
      title: 'Asset',
      dataIndex: 'assetName',
      key: 'assetName',
      sorter: true,
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
          <img src={record.assetIcon} alt={'img'} style={{ width: 25, height: 25 }} />
          {text}
        </div>
      ),
      width: '9%',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      width: '9%',
      render: (text: keyof typeof STATUSES) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
            <div
              style={{
                width: 6,
                height: 6,
                borderRadius: '50%',
                background: text.toLowerCase() === 'closed' ? '#FA541C' : '#52C41A',
              }}
            />
            {STATUSES[text]}
          </div>
        );
      },
    },
    {
      title: 'Wager',
      dataIndex: 'wager',
      key: 'wager',
      sorter: true,
      width: '8%',
    },
    {
      title: 'Multiplier',
      dataIndex: 'multiplier',
      key: 'multiplier',
      sorter: true,
      render: (text) => {
        return <div>x{text}</div>;
      },
      width: '8%',
    },
    {
      title: 'Entry price',
      dataIndex: 'entryPrice',
      key: 'entryPrice',
      sorter: true,
    },
    {
      title: 'Exit price',
      dataIndex: 'exitPrice',
      key: 'exitPrice',
      sorter: true,
    },
    {
      title: 'Fee',
      dataIndex: 'commissionFee',
      key: 'commissionFee',
      sorter: true,
      width: '7%',
    },
    {
      title: 'ROI',
      dataIndex: 'roi',
      key: 'roi',
      sorter: true,
      render: (text) => {
        const numberValue = parseFloat(text);
        return <div style={{ color: numberValue > 0 ? '#52C41A' : '#F5222D' }}>{text}</div>;
      },
      width: '6%',
    },
    {
      title: 'PNL',
      dataIndex: 'pnl',
      key: 'pnl',
      sorter: true,
      render: (text) => {
        const numberValue = parseFloat(text.replace(/[+$,]/g, ''));
        return <div style={{ color: numberValue > 0 ? '#52C41A' : '#F5222D' }}>{text}</div>;
      },
      width: '7%',
    },
  ];

  return (
    <div className={cn(styles.wrapper, isAppliedFilters && styles.wrapper_short)}>
      <Table<DataType>
        columns={columns}
        dataSource={newData}
        rowKey='key'
        pagination={{
          current: paginationConfig.page,
          pageSize: paginationConfig.size,
          total: paginationConfig.total || 0,
          showSizeChanger: true,
          showQuickJumper: true,
          onChange: handlePaginationChange,
        }}
        onChange={handleTableChange}
        scroll={{ y: window.innerHeight - (isAppliedFilters ? 267 : 205) }}
        loading={loading}
      />
    </div>
  );
};

export default BetsManagementTable;
