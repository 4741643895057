import { useCallback, useEffect } from 'react';

import setupAxiosInterceptors from '../api/axiosInstance';
import { useAppDispatch } from '../store';
import { logoutAction } from '../store/user/slice';

const ApiService = () => {
  const dispatch = useAppDispatch();

  const handleLogout = useCallback(() => {
    dispatch(logoutAction());
  }, [dispatch]);

  useEffect(() => {
    setupAxiosInterceptors(handleLogout);
  }, [handleLogout]);

  return null;
};

export default ApiService;
