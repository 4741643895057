import AdminManagementTable from './AdminManagementTable/AdminManagementTable';
import Toolbar from './Toolbar/Toolbar';

export default function AdminManagementPage() {
  return (
    <div>
      <Toolbar />
      <AdminManagementTable />
    </div>
  );
}
