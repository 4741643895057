import { useDispatch } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';

import assetsReducer from './assets/slice';
import betsSlice from './bets/slice';
import uiSlice from './ui/slice';
import userReducer from './user/slice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    ui: uiSlice,
    assets: assetsReducer,
    bets: betsSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
