import UnauthorizedLayout from '../../layouts/UnauthorizedLayout/UnauthorizedLayout';

import LoginForm from './LoginForm/LoginForm';

export default function LoginPage() {
  return (
    <UnauthorizedLayout subtext='Welcome back' title='Login to your account'>
      <LoginForm />
    </UnauthorizedLayout>
  );
}
