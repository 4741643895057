import { Button } from 'antd';

import styles from './PasswordBlock.module.scss';

export default function PasswordBlock() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>Password</div>
      <div className={styles.text}>
        Update your password through the button below. You will be redirected to a new page and must follow the
        instructions.
      </div>
      <div className={styles.button}>
        <Button>Reset password</Button>
      </div>
    </div>
  );
}
