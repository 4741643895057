import Avatar from './Avatar/Avatar';
import FormBlock from './FormBlock/FormBlock';
import PasswordBlock from './PasswordBlock/PasswordBlock';

import styles from './ProfileDetailsPage.module.scss';

export default function ProfileDetailsPage() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>Profile Details</div>
      <div className={styles.main}>
        <Avatar />
        <FormBlock />
        <PasswordBlock />
      </div>
    </div>
  );
}
