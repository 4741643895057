import UnauthorizedLayout from '../../layouts/UnauthorizedLayout/UnauthorizedLayout';

import ForgotPasswordForm from './ForgotPasswordForm/ForgotPasswordForm';

export default function ForgotPasswordPage() {
  return (
    <UnauthorizedLayout
      title={'Reset password'}
      text={'Enter the email address that you use to sign in to your CoinPoker Admin Panel account'}
    >
      <ForgotPasswordForm />
    </UnauthorizedLayout>
  );
}
