import React from 'react';
import { DeleteOutlined, EditOutlined, LockOutlined } from '@ant-design/icons';
import { Space, Tooltip } from 'antd';

import { DataType } from '../types';

interface ActionsProps {
  record: DataType;
  onOpenEditModal: (record: DataType) => void;
  onOpenDeleteModal: (email: string) => void;
}

const iconStyle = { fontSize: '16px', cursor: 'pointer' as const };

export default function Actions({ record, onOpenEditModal, onOpenDeleteModal }: ActionsProps) {
  return (
    <Space size='middle'>
      <Tooltip placement='top' title='Block'>
        <LockOutlined style={{ ...iconStyle, color: '#00000073' }} />
      </Tooltip>
      <Tooltip placement='top' title='Edit'>
        <EditOutlined onClick={() => onOpenEditModal(record)} style={{ ...iconStyle, color: '#00000073' }} />
      </Tooltip>
      <Tooltip placement='top' title='Delete'>
        <DeleteOutlined onClick={() => onOpenDeleteModal(record.email)} style={{ ...iconStyle, color: '#FF4D4F' }} />
      </Tooltip>
    </Space>
  );
}
