import { Button, Form } from 'antd';

import InputField from '../../../components/InputField/InputField';

import styles from './FormBlock.module.scss';

interface FormValues {
  fullName: string;
  email: string;
}

export default function FormBlock() {
  const [form] = Form.useForm<FormValues>();

  const onSubmit = async (values: FormValues) => {
    console.log('values', values);
  };

  return (
    <Form onFinish={onSubmit} className={styles.wrapper} requiredMark={false} form={form}>
      <div className={styles.item}>
        <InputField
          label='Full Name'
          name='fullName'
          rules={[{ required: true, message: 'Please enter your full name' }]}
          placeholder={''}
          disabled
        />
        <div className={styles.button}>
          <Button>Edit</Button>
        </div>
      </div>
      <div className={styles.item}>
        <InputField
          label='Email'
          name='email'
          rules={[{ required: true, message: 'Please enter your email' }]}
          placeholder={''}
          disabled
        />
      </div>
    </Form>
  );
}
