import { useEffect } from 'react';

import { useAppDispatch } from '../../store';
import { getAssets } from '../../store/assets/asyncThunks/slice';

import CryptoAssetsTable from './CryptoAssetsTable/CryptoAssetsTable';
import Toolbar from './Toolbar/Toolbar';

export default function CryptoAssetsPage() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAssets());
  }, []);

  return (
    <div>
      <Toolbar />
      <CryptoAssetsTable />
    </div>
  );
}
