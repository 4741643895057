import React from 'react';
import { Switch } from 'antd';

import styles from './StatusBlock.module.scss';

interface StatusBlockProps {
  status: boolean;
  onToggle: () => void;
}

export default function StatusBlock({ status, onToggle }: StatusBlockProps) {
  return (
    <div className={styles.status}>
      <div className={styles.status_title}>Status</div>
      <div className={styles.status_switch}>
        <span className={styles.status_switch_label}>Off</span>
        <Switch value={status} onChange={onToggle} />
        <span>On</span>
      </div>
    </div>
  );
}
