import React from 'react';
import { FormInstance } from 'antd';

import CustomSelect from '../../../components/CustomSelect/CustomSelect';
import DoubleInputField from '../../../components/DoubleInputField/DoubleInputField';
import InputField from '../../../components/InputField/InputField';

import ImageBlock from './ImageBlock/ImageBlock';
import StatusBlock from './StatusBlock/StatusBlock';

import styles from './CryptoAssetForm.module.scss';

interface CryptoAssetFormProps {
  form: FormInstance;
  status: boolean;
  onToggle: () => void;
}

export default function CryptoAssetForm({ form, status, onToggle }: CryptoAssetFormProps) {
  const requiredLabel = (text: string) => (
    <div>
      {text} <span style={{ color: '#ff4d4f' }}>*</span>
    </div>
  );

  return (
    <div className={styles.main}>
      <div className={styles.item}>
        <CustomSelect
          label={requiredLabel('Asset name')}
          name='symbol'
          placeholder='Enter asset name'
          options={[
            { value: 'BTCUSD', label: 'BTCUSD' },
            { value: 'ETHUSD', label: 'ETHUSD' },
            { value: 'TEST', label: 'TEST' },
          ]}
          rules={[{ required: true, message: 'Please enter asset name' }]}
        />
        <InputField
          label={requiredLabel('Display name')}
          name='name'
          rules={[
            { required: true, message: 'Please enter display name' },
            { min: 3, message: 'Display  name must be at least 3 characters long' },
          ]}
          placeholder='Enter display name'
        />
      </div>
      <div className={styles.item}>
        <DoubleInputField
          label={requiredLabel('Wager (min/max)')}
          name1='wagerMin'
          name2='wagerMax'
          rules1={[
            { required: true, message: 'Please enter wager min' },
            {
              validator: (_, value) => {
                if (value < 0) {
                  return Promise.reject(new Error('Wager min must not be negative'));
                }
                return Promise.resolve();
              },
            },
          ]}
          rules2={[
            { required: true, message: 'Please enter wager max' },
            {
              validator: (_, value) => {
                const min = form.getFieldValue('wagerMin');
                if (value < 0) {
                  return Promise.reject(new Error('Wager max must not be negative'));
                }
                if (value <= min) {
                  return Promise.reject(new Error('Wager max must be greater than wager min'));
                }
                return Promise.resolve();
              },
            },
          ]}
          placeholder1='Min'
          placeholder2='Max'
        />
        <DoubleInputField
          label='Multiplier (min/max)'
          name1='multiplierMin'
          name2='multiplierMax'
          rules1={[
            { required: true, message: 'Please enter multiplier min' },
            {
              validator: (_, value) => {
                if (value < 0) {
                  return Promise.reject(new Error('Multiplier min must not be negative'));
                }
                return Promise.resolve();
              },
            },
          ]}
          rules2={[
            { required: true, message: 'Please enter multiplier max' },
            {
              validator: (_, value) => {
                const minValue = form.getFieldValue('multiplierMin');
                if (value < 0) {
                  return Promise.reject(new Error('Multiplier max must not be negative'));
                }
                if (value <= minValue) {
                  return Promise.reject(new Error('Multiplier max must be greater than multiplier min'));
                }
                return Promise.resolve();
              },
            },
          ]}
          placeholder1='Min'
          placeholder2='Max'
        />
      </div>
      <div className={styles.item}>
        <InputField
          label={requiredLabel('Limit (take profit)')}
          name='takeProfitLimit'
          rules={[
            { required: true, message: 'Please enter limit (take profit)' },
            {
              validator: (_, value) => {
                if (value < 0) {
                  return Promise.reject(new Error('Take profit must not be negative'));
                }
                return Promise.resolve();
              },
            },
          ]}
          placeholder='Enter limit'
          type='number'
        />
        <InputField
          label={requiredLabel('Limit (stop loss)')}
          name='stopLossLimit'
          rules={[
            { required: true, message: 'Please enter limit (stop loss)' },
            {
              validator: (_, value) => {
                if (value < 0) {
                  return Promise.reject(new Error('Stop loss must not be negative'));
                }
                return Promise.resolve();
              },
            },
          ]}
          placeholder='Enter limit'
          type='number'
        />
      </div>
      <div className={styles.item}>
        <InputField
          label={requiredLabel('Flat Fee')}
          name='flatFeePercent'
          rules={[
            { required: true, message: 'Please enter Flat fee' },
            {
              validator: (_, value) => {
                if (value < 0) {
                  return Promise.reject(new Error('Flat fee must not be negative'));
                }
                return Promise.resolve();
              },
            },
          ]}
          placeholder='0.00%'
          type='number'
        />
        <InputField
          label={requiredLabel('PNL Fee')}
          name='pnlFeePercent'
          rules={[
            { required: true, message: 'Please enter PNL fee' },
            {
              validator: (_, value) => {
                if (value < 0) {
                  return Promise.reject(new Error('PNL fee must not be negative'));
                }
                return Promise.resolve();
              },
            },
          ]}
          placeholder='0.00%'
          type='number'
        />
      </div>
      <div className={styles.item}>
        <InputField
          label={requiredLabel('Funding Fee Percent')}
          name='fundingFeePercent'
          rules={[
            { required: true, message: 'Please enter Funding Fee Percent' },
            {
              validator: (_, value) => {
                if (value < 0) {
                  return Promise.reject(new Error('Funding Fee Percent must not be negative'));
                }
                return Promise.resolve();
              },
            },
          ]}
          placeholder='Enter %'
          type='number'
        />
        <InputField
          label={requiredLabel('Funding Fee Timeframe')}
          name='fundingFeeTimeframe'
          rules={[
            { required: true, message: 'Please enter Funding Fee Timeframe' },
            {
              validator: (_, value) => {
                if (value < 0) {
                  return Promise.reject(new Error('Funding Fee Timeframe must not be negative'));
                }
                return Promise.resolve();
              },
            },
          ]}
          placeholder='Enter N of hours'
          type='number'
        />
      </div>
      <div className={styles.item}>
        <ImageBlock />
        <StatusBlock status={status} onToggle={onToggle} />
      </div>
    </div>
  );
}
