export function generatePassword(length = 12) {
  const upperCaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const lowerCaseChars = 'abcdefghijklmnopqrstuvwxyz';
  const numberChars = '0123456789';
  const symbolChars = '!@#$%^&*()_+[]{}|;:,.<>?';

  const allChars = upperCaseChars + lowerCaseChars + numberChars + symbolChars;
  let password = '';

  password += upperCaseChars.charAt(Math.floor(Math.random() * upperCaseChars.length));
  password += lowerCaseChars.charAt(Math.floor(Math.random() * lowerCaseChars.length));
  password += numberChars.charAt(Math.floor(Math.random() * numberChars.length));
  password += symbolChars.charAt(Math.floor(Math.random() * symbolChars.length));

  for (let i = password.length; i < length; i++) {
    password += allChars.charAt(Math.floor(Math.random() * allChars.length));
  }

  password = password
    .split('')
    .sort(() => Math.random() - 0.5)
    .join('');

  return password;
}
