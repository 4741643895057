import React from 'react';

import CustomSearch from '../../../components/CustomSearch/CustomSearch';
import { FilterItem, FilterRange } from '../types';

import FilterDropdown from './FilterDropdown/FilterDropdown';
import FilterList from './FilterList/FilterList';

import styles from './Toolbar.module.scss';

interface ToolbarProps {
  selectedAsset: string;
  selectedUser: string;
  selectedStatus: string;
  wagerRange: FilterRange;
  multiplierRange: FilterRange;
  filterList: FilterItem[];
  setSelectedAsset: (asset: string) => void;
  setSelectedUser: (user: string) => void;
  setSelectedStatus: (status: string) => void;
  setWagerRange: (range: FilterRange) => void;
  setMultiplierRange: (range: FilterRange) => void;
  onApply: () => void;
  onRemoveFilterItem: (value: string) => void;
}

export default function Toolbar({
  filterList,
  setSelectedAsset,
  setSelectedUser,
  setSelectedStatus,
  setWagerRange,
  setMultiplierRange,
  onApply,
  onRemoveFilterItem,
  ...filters
}: ToolbarProps) {
  return (
    <div className={styles.toolbar}>
      <div className={styles.toolbar_top}>
        <div className={styles.toolbar_top_title}>Bets Management</div>
        <div className={styles.toolbar_top_right}>
          <FilterDropdown
            setSelectedAsset={setSelectedAsset}
            setSelectedUser={setSelectedUser}
            setSelectedStatus={setSelectedStatus}
            setWagerRange={setWagerRange}
            setMultiplierRange={setMultiplierRange}
            onApply={onApply}
            {...filters}
          />
          <CustomSearch />
        </div>
      </div>
      <FilterList filterList={filterList} onRemoveFilterItem={onRemoveFilterItem} />
    </div>
  );
}
