import React, { MouseEvent, RefObject } from 'react';
import { Button } from 'antd';
import cn from 'classnames';

import BTCImage from '../../../../../assets/images/btc.png';
import CustomSelect from '../../../../../components/CustomSelect/CustomSelect';
import { FilterRange } from '../../../types';

import RangeFilterDropdown from './RangeFilterDropdown/RangeFilterDropdown';

import styles from './FilterDropdownContent.module.scss';

interface FilterDropdownContentProps {
  visible: boolean;
  dropdownInnerRef: RefObject<HTMLDivElement>;
  selectedAsset: string;
  selectedUser: string;
  selectedStatus: string;
  wagerRange: any;
  multiplierRange: any;
  setSelectedAsset: (asset: string) => void;
  setSelectedUser: (user: string) => void;
  setSelectedStatus: (status: string) => void;
  setWagerRange: (range: FilterRange) => void;
  setMultiplierRange: (range: FilterRange) => void;
  onClose: () => void;
  onApply: () => void;
}

const MIN_VALUE = 0;
const MAX_VALUE = 1000;

const assetOptions = [
  { value: 'BTC/USD', label: 'BTC/USD', icon: BTCImage },
  { value: 'ETH/USD', label: 'ETH/USD', icon: BTCImage },
];

const userOptions = [
  { value: 'franki576', label: 'franki576' },
  { value: 'cryptoKing42', label: 'cryptoKing42' },
];

const statusOptions = [
  { value: 'Active', label: 'Active' },
  { value: 'Closed', label: 'Closed' },
];

const createOptionWithIcon = (options: { value: string; label: string; icon?: string }[]) =>
  options.map(({ value, label, icon }) => ({
    value,
    label: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {icon && <img src={icon} alt='' style={{ marginRight: 8 }} />}
        {label}
      </div>
    ),
  }));

export default function FilterDropdownContent({
  visible,
  dropdownInnerRef,
  setSelectedAsset,
  setSelectedUser,
  setSelectedStatus,
  setWagerRange,
  setMultiplierRange,
  onClose,
  onApply,
  ...filters
}: FilterDropdownContentProps) {
  const handleMouseDown = (e: MouseEvent) => e.stopPropagation();

  const { selectedAsset, selectedUser, multiplierRange, wagerRange, selectedStatus } = filters || {};

  return (
    <div
      ref={dropdownInnerRef}
      className={cn(styles.inner, visible && styles.inner_visible)}
      onMouseDown={handleMouseDown}
    >
      <div className={styles.inner_item}>
        <CustomSelect
          label='Filter by Asset'
          placeholder='Please select...'
          options={createOptionWithIcon(assetOptions)}
          showSearch
          value={selectedAsset}
          onChange={setSelectedAsset}
          withIcon
        />
      </div>
      <div className={styles.inner_item}>
        <CustomSelect
          label='Filter by User'
          placeholder='Please select...'
          options={createOptionWithIcon(userOptions)}
          showSearch
          value={selectedUser}
          onChange={setSelectedUser}
        />
      </div>
      <div className={styles.inner_sliderItem}>
        <RangeFilterDropdown
          min={MIN_VALUE}
          max={MAX_VALUE}
          minValue={multiplierRange?.minValue}
          maxValue={multiplierRange?.maxValue}
          isChangeRange={multiplierRange?.isChangeRange}
          label='Filter by Multiplier'
          placeholder='Please select...'
          onMinChange={(min) => setMultiplierRange({ ...multiplierRange, minValue: min, isChangeRange: true })}
          onMaxChange={(max) => setMultiplierRange({ ...multiplierRange, maxValue: max, isChangeRange: true })}
          onSliderChange={(values) =>
            setMultiplierRange({ ...multiplierRange, minValue: values[0], maxValue: values[1], isChangeRange: true })
          }
        />
      </div>
      <div className={styles.inner_sliderItem}>
        <RangeFilterDropdown
          min={MIN_VALUE}
          max={MAX_VALUE}
          minValue={wagerRange.minValue}
          maxValue={wagerRange.maxValue}
          isChangeRange={wagerRange.isChangeRange}
          label='Filter by Wager'
          placeholder='Please select...'
          onMinChange={(min) => setWagerRange({ ...wagerRange, minValue: min, isChangeRange: true })}
          onMaxChange={(max) => setWagerRange({ ...wagerRange, maxValue: max, isChangeRange: true })}
          onSliderChange={(values) =>
            setWagerRange({ ...wagerRange, minValue: values[0], maxValue: values[1], isChangeRange: true })
          }
        />
      </div>
      <div className={styles.inner_item}>
        <CustomSelect
          label='Filter by Status'
          placeholder='Please select...'
          options={statusOptions}
          value={selectedStatus}
          onChange={setSelectedStatus}
        />
      </div>
      <div className={styles.inner_buttons}>
        <Button key='cancel' onClick={onClose}>
          Cancel
        </Button>
        <Button key='ok' type='primary' className={styles.inner_buttons_okButton} onClick={onApply}>
          Apply
        </Button>
      </div>
    </div>
  );
}
