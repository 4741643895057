import { createAsyncThunk } from '@reduxjs/toolkit';

import { requester } from '../../../api/axiosInstance';
import { GetAssetsResponse, IAssetRequest, IRequestDeleteAssetData } from '../types';

export const createAsset = createAsyncThunk('/assets/createAsset', async (body: IAssetRequest) => {
  return requester.post('/assets', body);
});

export const getAssets = createAsyncThunk<GetAssetsResponse, void, { rejectValue: string }>(
  'assets/getAssets',
  async (_, { rejectWithValue }) => {
    try {
      const response = await requester.get('/assets');
      return { data: response.data };
    } catch (error) {
      return rejectWithValue('Failed to fetch assets');
    }
  },
);

export const deleteAsset = createAsyncThunk('/assets/deleteAsset', async (assetId: number) => {
  return requester.delete(`/assets/${assetId}`);
});

export const editAsset = createAsyncThunk(
  '/assets/editAsset',
  async (requestDeleteAssetData: IRequestDeleteAssetData) => {
    return requester.put(`/assets/${requestDeleteAssetData.assetId}`, requestDeleteAssetData.body);
  },
);
