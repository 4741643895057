import { Link } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Form } from 'antd';

import InputField from '../../../components/InputField/InputField';

import styles from './ForgotPasswordForm.module.scss';

type ValuesType = {
  email: string;
};

export default function ForgotPasswordForm() {
  const onSubmit = async (values: ValuesType) => {
    console.log('values', values);
  };

  return (
    <Form onFinish={onSubmit} className={styles.form} requiredMark={false}>
      <InputField
        label='Email'
        name='email'
        rules={[
          { required: true, message: 'Please enter your email' },
          { type: 'email', message: 'Please enter a valid email' },
        ]}
        placeholder='Enter your email'
      />
      <div className={styles.formButtons}>
        <Link to={'/'} className={styles.formButtons_link}>
          <ArrowLeftOutlined style={{ marginRight: 5, marginTop: 2 }} />
          <span>Back to Login</span>
        </Link>
        <Button type='primary' htmlType='submit' className={styles.formButtons_button}>
          Request reset link
        </Button>
      </div>
    </Form>
  );
}
