import { Navigate } from 'react-router-dom';

import { useTypedSelector } from '../hooks/useTypedSelector';

const PrivateRoutes = ({ children }) => {
  const { isAuth } = useTypedSelector((state) => state.user);

  if (isAuth) {
    return children;
  }

  return <Navigate to='/' state={{ from: window.location.pathname }} replace />;
};

export default PrivateRoutes;
