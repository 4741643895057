import { ReactNode } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';

import styles from './ConfirmModal.module.scss';

const modalStyles = {
  content: {
    padding: 0,
  },
};

interface ConfirmModalProps {
  isModalOpen: boolean;
  title: string;
  text: string | ReactNode;
  okButtonText: string;
  handleCancel: () => void;
  handleOk: () => void;
}

export default function ConfirmModal({
  isModalOpen,
  title,
  text,
  okButtonText,
  handleCancel,
  handleOk,
}: ConfirmModalProps) {
  return (
    <Modal title='' open={isModalOpen} onCancel={handleCancel} footer={null} styles={modalStyles} closable={false}>
      <div className={styles.main}>
        <InfoCircleOutlined style={{ fontSize: 22, color: '#FAAD14' }} />
        <div>
          <div className={styles.title}>{title}</div>
          <div className={styles.text}>{text}</div>
        </div>
      </div>
      <div className={styles.footer}>
        <Button key='cancel' onClick={handleCancel} className={styles.cancelButton}>
          Cancel
        </Button>
        <Button key='ok' type='primary' onClick={handleOk} className={styles.okButton}>
          {okButtonText}
        </Button>
      </div>
    </Modal>
  );
}
