import { createSlice } from '@reduxjs/toolkit';

import { getAssets } from './asyncThunks/slice';
import { AssetsState } from './types';

const initialState: AssetsState = {
  assets: [],
  loading: false,
  error: null,
};

export const assetsSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAssets.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAssets.fulfilled, (state, action) => {
      state.assets = action.payload.data;
      state.loading = false;
    });
    builder.addCase(getAssets.rejected, (state, action) => {
      state.assets = [];
      state.loading = false;
      state.error = action.payload || 'Unknown error';
    });
  },
});

export const {} = assetsSlice.actions;

export default assetsSlice.reducer;
