import React, { useEffect, useState } from 'react';
import type { TablePaginationConfig } from 'antd';
import { Switch, TableProps } from 'antd';
import { Table } from 'antd';

import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { useAppDispatch } from '../../../store';
import { editAsset } from '../../../store/assets/asyncThunks/slice';

import Actions from './Actions/Actions';
import DeleteAssetModal from './DeleteAssetModal/DeleteAssetModal';
import EditAssetModal from './EditAssetModal/EditAssetModal';
import { DataType } from './types';

import styles from './CryptoAssetsTable.module.scss';

export default function CryptoAssetsTable() {
  const dispatch = useAppDispatch();
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState<boolean>(false);

  const [data, setData] = useState<DataType[]>([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [editAssetData, setEditAssetData] = useState<DataType | null>(null);
  const [deleteAssetData, setDeleteAssetData] = useState<number | null>(null);

  const { assets, loading } = useTypedSelector((state) => state.assets);

  useEffect(() => {
    setData(assets as DataType[]);
  }, [assets]);

  const handleOpenEditModal = (dataEditAsset: DataType) => {
    setEditAssetData(dataEditAsset);
    setIsOpenEditModal(true);
  };

  const handleTableChange = (paginationData: TablePaginationConfig) => {
    setPagination({ current: paginationData.current ?? 1, pageSize: paginationData.pageSize ?? 10 });
  };

  const handlePaginationChange = (page: number, pageSize?: number) => {
    handleTableChange({ current: page, pageSize });
  };
  const handleOpenDeleteModal = (isRecordToggle: boolean, assetId: number) => {
    if (!isRecordToggle) {
      setIsOpenDeleteModal(true);
      setDeleteAssetData(assetId);
    }
  };

  const handleCloseDeleteModal = () => {
    setIsOpenDeleteModal(false);
  };

  const handleToggle = (id: string | number) => {
    const toggledData = data.map((item) => (item.id === id ? { ...item, isActive: !item.isActive } : item));
    setData(toggledData);

    const selectedAsset = toggledData.find((item) => item.id === id);

    if (selectedAsset) {
      const updatePayload = {
        assetId: id as number,
        body: selectedAsset,
      };
      dispatch(editAsset(updatePayload));
    }
  };

  const handleCloseEditModal = () => {
    setIsOpenEditModal(false);
  };

  const columns: TableProps<DataType>['columns'] = [
    {
      title: 'Img',
      dataIndex: 'icon',
      key: 'icon',
      render: (icon) => <img src={icon} alt='Asset' style={{ width: 25, height: 25 }} />,
      width: '4%',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Asset name',
      dataIndex: 'symbol',
      key: 'symbol',
      sorter: (a, b) => a.symbol.localeCompare(b.symbol),
      width: '8%',
    },
    {
      title: 'Wager (min/max)',
      dataIndex: 'wagerMin',
      key: 'wagerMin',
      render: (text, record) => {
        console.log('record', record);
        return (
          <div className={styles.toggleContainer}>
            <div>
              <span style={{ color: '#00000073' }}>min </span>
              {record?.wagerMin}
            </div>
            /
            <div>
              <span style={{ color: '#00000073' }}>max </span>
              {record?.wagerMax}
            </div>
          </div>
        );
      },
      width: '10%',
    },
    {
      title: 'Multiplier (min/max)',
      dataIndex: 'multiplierMIn',
      key: 'multiplierMin',
      render: (text, record) => {
        return (
          <div className={styles.toggleContainer}>
            <div>
              <span style={{ color: '#00000073' }}>min </span>
              {record.multiplierMin}
            </div>
            /
            <div>
              <span style={{ color: '#00000073' }}>max </span>
              {record.multiplierMax}
            </div>
          </div>
        );
      },
      width: '12%',
    },
    {
      title: 'Limit (take profit)',
      dataIndex: 'takeProfitLimit',
      key: 'takeProfitLimit',
      sorter: (a, b) => a.takeProfitLimit.toString().localeCompare(b.takeProfitLimit.toString()),
    },
    {
      title: 'Limit (stop loss)',
      dataIndex: 'stopLossLimit',
      key: 'stopLossLimit',
      sorter: (a, b) => a.stopLossLimit.toString().localeCompare(b.stopLossLimit.toString()),
    },
    {
      title: 'Flat Fee',
      dataIndex: 'flatFeePercent',
      key: 'flatFeePercent',
      sorter: (a, b) => a.flatFeePercent.toString().localeCompare(b.flatFeePercent.toString()),
      render: (text) => {
        return <span>{text}%</span>;
      },
      width: '7%',
    },
    {
      title: 'PNL Fee',
      dataIndex: 'pnlFeePercent',
      key: 'pnlFeePercent',
      sorter: (a, b) => a.pnlFeePercent.toString().localeCompare(b.pnlFeePercent.toString()),
      render: (text) => {
        return <span>{text}%</span>;
      },
      width: '7%',
    },
    {
      title: 'Funding Fee',
      dataIndex: 'fundingFeePercent',
      key: 'fundingFeePercent',
      sorter: (a, b) => a.fundingFeePercent.toString().localeCompare(b.fundingFeePercent.toString()),
      render: (text) => {
        return <span>{text}%</span>;
      },
      width: '8%',
    },
    {
      title: '',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (value, record) => (
        <div className={styles.toggleContainer}>
          <span className={styles.toggleLabel}>Off</span>
          <Switch value={value} onChange={() => handleToggle(record.id)} />
          <span>On</span>
        </div>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Actions
          record={record}
          onOpenEditModal={handleOpenEditModal}
          onOpenDeleteModal={() => handleOpenDeleteModal(record.isActive, record.id)}
        />
      ),
      width: '10%',
    },
  ];

  return (
    <div className={styles.wrapper}>
      <Table<DataType>
        columns={columns}
        dataSource={data}
        rowKey='id'
        pagination={{
          current: pagination.current,
          pageSize: pagination.pageSize,
          total: data?.length,
          showSizeChanger: true,
          showQuickJumper: true,
          onChange: handlePaginationChange,
        }}
        onChange={handleTableChange}
        scroll={{ y: window.innerHeight - 225, x: 1600 }}
        loading={loading}
      />
      <DeleteAssetModal
        isModalOpen={isOpenDeleteModal}
        assetId={deleteAssetData}
        handleCancel={handleCloseDeleteModal}
      />
      <EditAssetModal isModalOpen={isOpenEditModal} editAssetData={editAssetData} handleCancel={handleCloseEditModal} />
    </div>
  );
}
